
/* ----------------------------------------
    Imports
---------------------------------------- */

import { Component, Prop, Vue } from 'vue-property-decorator';

/* ----------------------------------------
    Component
---------------------------------------- */

@Component({
  props: {
    text: String,
    url: String,
    label: String,
    noIcon: {
      type: Boolean,
      default: false
    }
  }
})

/* ----------------------------------------
    Export
---------------------------------------- */
export default class ListItem extends Vue {}
