
/* ----------------------------------------

<script lang='ts'>
/* ----------------------------------------
    Imports
---------------------------------------- */

import { Component, Vue } from 'vue-property-decorator';
import busSvg from '@svg/TransportBus.vue';
import trainSvg from '@svg/TransportTrain.vue';
import tramSvg from '@svg/TransportTram.vue';
import metroSvg from '@svg/TransportMetro.vue';
import bikeSvg from '@svg/TransportBike.vue';
import ferrySvg from '@svg/TransportFerry.vue';

/* ----------------------------------------
    Component
---------------------------------------- */

@Component({
  components: {
    busSvg,
    trainSvg,
    tramSvg,
    metroSvg,
    bikeSvg,
    ferrySvg,
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  computed: {},
  methods: {
    getComponentName(type) {
      console.log(type, 'type');
      return `${type}Svg`;
    }
  }
})

/* ----------------------------------------
    Export
---------------------------------------- */
export default class TransportationBlock extends Vue { }
