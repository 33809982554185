
/* ----------------------------------------
    Imports
---------------------------------------- */

import { Component, Vue } from 'vue-property-decorator';
import { setTitle, handle429 } from '@util/util.ts';
import { app } from '@config';
import ButtonGroup from '../../../fruitpackage/components/ButtonGroup.vue';
import CopyUrl from '@part/elements/CopyUrl.vue';
import CardDefault from '@part/elements/CardDefault.vue';
import MuseumInfo from '@part/elements/MuseumInfo.vue';
import ListItem from '@part/elements/ListItem.vue';
import MyNightButton from '@part/elements/MyNightButton.vue';
import ArrowBackSvg from '@svg/ArrowBack.vue';
import ArrowForwardSvg from '@svg/ArrowForward.vue';
import RefreshRedSvg from '@svg/RefreshRed.vue';
import ChainSvg from '@svg/Chain.vue';
import PinBoldSvg from '@svg/PinBold.vue';
import ClockBoldSvg from '@svg/ClockBold.vue';
import CalendarSvg from '@svg/Calendar.vue';
import AmbassadorsCard from '@part/elements/AmbassadorsCard.vue';
import TransportationBlock from '@part/blocks/TransportationBlock.vue';
import TransportationBlockExtra from '@part/blocks/TransportationBlockExtra.vue';

import axios from 'axios';
import HighlightedAmbassadorsBlock from '@part/blocks/HighlightedAmbassadorsBlock.vue';
import OtherEvents from '@part/elements/OtherEvents.vue';
import ExternalSvg from '@svg/External.vue';
import ExternalSvgColor from '@svg/ExternalColor.vue';
import FacebookSvg from '@svg/Facebook.vue';
import InstagramSvg from '@svg/Instagram.vue';
import TiktokSvg from '@svg/Tiktok.vue';
import $ from 'jquery';
import slick from 'slick-carousel';

/* ----------------------------------------
    Component
---------------------------------------- */

@Component({
  components: {
    ArrowBackSvg,
    ExternalSvg,
    ExternalSvgColor,
    FacebookSvg,
    InstagramSvg,
    TiktokSvg,
    AmbassadorsCard,
    CalendarSvg,
    ListItem,
    MuseumInfo,
    HighlightedAmbassadorsBlock,
    CardDefault,
    MyNightButton,
    CopyUrl,
    ButtonGroup,
    ArrowForwardSvg,
    RefreshRedSvg,
    ChainSvg,
    PinBoldSvg,
    ClockBoldSvg,
    OtherEvents,
    TransportationBlock,
    TransportationBlockExtra
  },
  data() {
    return {
      textHeight: 80,
      hasReadMore: this.readmore,
    };
  },
  props: {
    type: {
      type: String
    },
    tiktok: {
      type: String,
      default: ''
    },
    instagram: {
      type: String,
      default: ''
    },
    facebook: {
      type: String,
      default: ''
    },
    website: {
      type: String,
      default: ''
    },
    backgroundLine: {
      type: Boolean,
      default: false
    },
    readmore: {
      type: Boolean,
      default: false
    },
    moreInfoLink: {
      type: String,
      default: ''
    },
    buyTicket: {
      type: String,
      default: ''
    },
    backLink: {
      type: Object
    },
    title: {
      type: String
    },
    images: {
      type: Array
    },
    routes: {
      type: Array
    },
    ambassador_tip: {
      type: Object
    },
    program: {
      type: Array
    },
    program_title: {
      type: String
    },
    duration: {
      type: String
    },
    date: {
      type: String
    },
    description: {
      type: String
    },
    museum: {
      type: Object
    },
    category: {
      type: String
    },
    id: {
      required: true,
      default: 0
    },
    phase: {
      type: String
    },
  },
  mounted() {
    if (this.$refs.text.clientHeight - 10 <= this.textHeight) {
      this.hasReadMore = false;
    }

    this.description = this.description.replace(/\n/g, '<br />');

    setTimeout(() => {
      slick;
      $('.event-detail__slider').slick({
        infinite: !!this.simple,
        slidesToShow: 1,
        arrows: true,
        dots: true,
        prevArrow: $('.prev-arrow'),
        nextArrow: $('.next-arrow'),
        adaptiveHeight: true
      });
    }, 500);
  },
  methods: {
    fixUrl(url) {
      let newUrl = window.decodeURIComponent(url);
      newUrl = newUrl.trim().replace(/\s/g, '');
      if (/^(:\/\/)/.test(newUrl)) {
        return `https${newUrl}`;
      }
      if (!/^(f|ht)tps?:\/\//i.test(newUrl)) {
        return `https://${newUrl}`;
      }
      return newUrl;
    },
    sliderTitle(category) {
      return `Meer in ${category}`;
    },
    toggleReadmore() {
      const defaultTextHeight = 80;

      if (this.textHeight === defaultTextHeight) {
        return (this.textHeight = this.$refs.text.clientHeight);
      }

      this.textHeight = defaultTextHeight;
    }
  },
})

/* ----------------------------------------
    Export
---------------------------------------- */
export default class DetailLayout extends Vue { }
