
/* ----------------------------------------
    Imports
---------------------------------------- */

import { Component, Vue } from 'vue-property-decorator';

/* ----------------------------------------
    Component
---------------------------------------- */

@Component({
  components: {

  },
  props: {
    data: {
      type: Object,
      required: false
    },
    id: {
      required: true,
    },
  },
  computed: {}
})

/* ----------------------------------------
    Export
---------------------------------------- */
export default class TransportationBlockExtra extends Vue { }
