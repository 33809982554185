
/* ----------------------------------------
    Imports
---------------------------------------- */

import { Component, Prop, Vue } from 'vue-property-decorator';
import ChevronSvg from '@svg/Chevron.vue';
/* ----------------------------------------
    Component
---------------------------------------- */

@Component({
  components: {
    ChevronSvg
  },
  data() {
    return {
      ambassador_cards: [],
      ambassador_label: 'Ambassadeur'
    };
  },
  props: {
    ambassadors: {
      type: Array,
      required: false
    },
    ambassador_tip: {
      type: Object,
      required: false
    },

  },

  created() {
    if (this.ambassadors && this.ambassadors.length > 0) {
      this.ambassador_cards = this.ambassadors;
    } else if (this.ambassador_tip) {
      this.ambassador_cards = [this.ambassador_tip];
      this.ambassador_label = 'Getipt door de ambassadeur';
    }
  }
})

/* ----------------------------------------
    Export
---------------------------------------- */
export default class AmbassadorsCard extends Vue { }
