

/* ----------------------------------------
        Imports
    ---------------------------------------- */

import { Component, Vue } from 'vue-property-decorator';
import CardDefault from '@part/elements/CardDefault.vue';
import ArrowForwardWhiteSvg from '@svg/ArrowForwardWhite.vue';

/* ----------------------------------------
        Component
    ---------------------------------------- */

@Component({
  components: {
    CardDefault,
    ArrowForwardWhiteSvg
  },
  props: ['data', 'title', 'minimal', 'bg_color'],
  computed: {
    shuffledData() {
      return this.shuffleArr(this.data);
    }
  },
  methods: {
    shuffleArr(a) {
      let j, x, i;
      for (i = a.length - 1; i > 0; i--) {
        j = Math.floor(Math.random() * (i + 1));
        x = a[i];
        a[i] = a[j];
        a[j] = x;
      }
      a > 10 && a.slice(0, 10);
      return a;
    }
  }
})

/* ----------------------------------------
        Export
    ---------------------------------------- */

export default class OtherEvents extends Vue { }

