
/* ----------------------------------------
    Imports
---------------------------------------- */

import { Component, Prop, Vue } from 'vue-property-decorator';
import ExternalSvg from '@svg/External.vue';
import ArrowForwardSvg from '@svg/ArrowForward.vue';
import WheelchairSvg from '@svg/Wheelchair.vue';
import CardSvg from '@svg/Card.vue';
import BarSvg from '@svg/Bar.vue';
import TourSvg from '@svg/Tour.vue';
import ToursSvg from '@svg/Tours.vue';
import TactillitySvg from '@svg/Tactillity.vue';
import GuidePathSvg from '@svg/GuidePath.vue';
import MultiLanguageSvg from '@svg/MultiLanguage.vue';
import SensetiveSvg from '@svg/Sensetive.vue';
import AlcoholFreeSvg from '@svg/AlcoholFree.vue';
import BrailleSvg from '@svg/Braille.vue';

/* ----------------------------------------
    Component
---------------------------------------- */

@Component({
  components: {
    ArrowForwardSvg,
    ExternalSvg,
    WheelchairSvg,
    CardSvg,
    TourSvg,
    BarSvg,
    TactillitySvg,
    GuidePathSvg,
    MultiLanguageSvg,
    SensetiveSvg,
    AlcoholFreeSvg,
    BrailleSvg,
    ToursSvg
  },
  props: {
    museum: {
      type: Object,
      required: false
    }
  },
})

/* ----------------------------------------
    Export
---------------------------------------- */
export default class MuseumInfo extends Vue { }
